<template>
  <section class="section">
    <h4 class="title has-text-centered is-4">Feat Epics</h4>
    <b-table
      :data="epicsData"
      :striped="true"
      :hoverable="true"
      :loading="loadingEpics"
      class="mt-4 clickable-table user-table"
      @click="goToEpic"
    >
      <b-table-column field="id" label="ID" v-slot="props">
        <p>
          {{ props.row.id }}
        </p>
      </b-table-column>

      <b-table-column field="name" label="Name" v-slot="props">
        <p>{{ props.row.name }}</p>
      </b-table-column>

    </b-table>

    <b-collapse :open="false" class="mt-6">
      <template #trigger>
        <b-button
          label="Create New Epic"
          icon-left="plus"
          type="is-info" />
      </template>
      <epic-form></epic-form>
    </b-collapse>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import EpicForm from '../../components/Feats/EpicForm.vue';

export default {
  name: 'Epics',
  components: { EpicForm },
  computed: {
    ...mapState('feats', ['loadingEpics', 'epicsData']),
  },
  mounted() {
    this.loadEpics();
  },
  methods: {
    loadEpics() {
      this.$store.dispatch('feats/loadEpics');
    },
    goToEpic(row) {
      this.$router.push({ path: `/epics/${row.id}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
</style>
