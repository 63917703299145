<template>
  <div>
    <h4 class="title has-text-centered is-4">{{ epic ? 'Epic Details' : 'New Epic' }}</h4>

    <div class="columns">
      <div class="column is-6">
        <form @submit="handleSubmit">
          <b-field label="Name" custom-class="is-small">
            <b-input v-model="formData.name" size="is-small"></b-input>
          </b-field>

          <b-field label="Description" custom-class="is-small">
            <b-input v-model="formData.description" type="textarea" size="is-small"></b-input>
          </b-field>

          <b-field label="Legend" custom-class="is-small">
            <b-input v-model="formData.legend" type="textarea" size="is-small"></b-input>
          </b-field>

          <b-button type="is-success" native-type="submit" >{{ epic ? 'Update' : 'Create' }}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Feat } from '@/services/api';

export default {
  name: 'epic-form',
  props: {
    epic: Object,
  },
  watch: {
    epic: {
      immediate: true,
      handler(val) {
        if (val) {
          this.formData = {
            ...val,
          };
        }
      },
    },
  },
  data() {
    return {
      errors: [],
      formData: {
        name: null,
        description: null,
        legend: null,
      },
      loadingForm: false,
      sent: false,
    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      const action = this.epic ? Feat.updateEpic(this.epic.id, this.formData) : Feat.createEpic(this.formData);

      action
        .then(() => {
          this.sent = true;
          if (this.epic) {
            this.refreshEpic();
          } else {
            this.refreshEpics();
          }
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    refreshEpic() {
      this.$store.dispatch('feats/loadEpicDetails', this.epic.id);
    },
    refreshEpics() {
      this.$store.dispatch('feats/loadEpics');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
